@import 'assets/scss/style';

.ris {
  &.form-control {
    padding: 0;
    .ris-input {
      border: 1px;
      padding: 10px 20px;
      font-size: 14px;
      height: 42px !important;
      overflow: hidden !important;
      &:focus {
        border: 1px solid #80bdff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
    .ris-options {
      z-index: 999;
    }
  }
}
.revenue {
  color: #3dffc1;
  font-size: 18px;
  padding: 0 15px;
}
